import * as React from 'react'

import { spacing } from '@thg-commerce/enterprise-theme'

import {
  Paragraph,
  StyledCheckmark,
  SubscriptionInfoBoxWrapper,
  TitleContainer,
} from './style'

export interface SubscriptionInfoBoxProps {
  title: {
    text: string
    icon?: React.ReactElement
  }
  subtitle?: React.ReactElement
  infoText?: {
    upsellText?: string
    infoTextList: string[]
  }
  subsText?: string
  additionalStyle?: {
    marginTop: number
    padding: number
  }
}

export const SubscriptionInfoBox = (props: SubscriptionInfoBoxProps) => {
  return (
    <SubscriptionInfoBoxWrapper additionalStyle={props.additionalStyle}>
      <TitleContainer>
        {props.title.text}
        {props.title.icon}
      </TitleContainer>
      {props.subtitle}
      {props.infoText && (
        <ul style={{ marginTop: `${spacing(1)}` }}>
          {props.infoText.upsellText && (
            <li
              style={{
                display: 'flex',
                marginBottom: `${spacing(2)}`,
                marginTop: 0,
              }}
            >
              <StyledCheckmark
                viewBox={'0 0 24 24'}
                aria-hidden="true"
                focusable="false"
              />
              <div style={{ paddingLeft: `${spacing(2)}` }}>
                {props.infoText.upsellText}
              </div>
            </li>
          )}
          {props.infoText.infoTextList?.length > 0 &&
            props.infoText.infoTextList.map((item, index) => (
              <li
                style={{
                  display: 'flex',
                  marginBottom: `${spacing(2)}`,
                  marginTop: 0,
                }}
                key={index}
              >
                <StyledCheckmark viewBox={'0 0 24 24'} />
                <div style={{ paddingLeft: `${spacing(2)}` }}>{item}</div>
              </li>
            ))}
        </ul>
      )}
      {props.subsText && <Paragraph>{props.subsText}</Paragraph>}
    </SubscriptionInfoBoxWrapper>
  )
}
