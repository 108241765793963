import * as React from 'react'

import {
  PlatformMessage,
  PlatformMessageType,
  RenderAnnouncerType,
} from '@thg-commerce/gravity-elements'

interface PageMessageProps {
  flashMessageId: string
  // message not from storage
  message: string | null
  // type of message not from storage
  type: PlatformMessageType
  className?: string
  renderAnnouncer?: RenderAnnouncerType
  urlParams?: {
    messageType: PlatformMessageType | ''
    messageText: string
    messageId: string
  }
  focusRef?: React.RefObject<HTMLDivElement>
  disableScroll?: boolean
}
interface PageMessage {
  text: string | null
  type: PlatformMessageType
}
interface FlashMessage {
  id: string
  text: string
  type: PlatformMessageType
  messageKey: string
  timeout?: number
}
interface HandleRouting {
  router: { push: (route: string) => void }
  route: string
}

const clientIsIE = (): boolean =>
  [
    /Trident\/7\.0.*rv\:([0-9\.]+).*\).*Gecko$/,
    /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/,
    /MSIE\s(7\.0)/,
  ].some((regex) => window.navigator.userAgent.match(regex))

const defaultStorageTimeout = 1000 * 60 * 5

const addFlashMessageToStorage = (init: FlashMessage): void => {
  const timeOut = init.timeout || defaultStorageTimeout
  window.localStorage.setItem(
    init.id,
    JSON.stringify({
      text: init.text,
      type: init.type,
      timeout: Date.now() + timeOut,
    }),
  )
}

export const setFlashMessageAndPushToRouter = (
  init: FlashMessage,
  routing: HandleRouting,
): void => {
  if (clientIsIE()) {
    /*
     * Internet Explorer does not support local storage therefore, in this case,
     * we rely on url paramaters to pass values between pages.
     */
    const url = routing.route
    const queryStart = url.indexOf('?')
    const hashStart = url.indexOf('#')
    const routeAndParams = url
      .slice(0, hashStart !== -1 ? hashStart : url.length + 1)
      .concat(
        `${queryStart !== -1 ? '&' : '?'}messageKey=${
          init.messageKey
        }&messageId=${init.id}&messageType=${init.type}${
          hashStart !== -1 ? url.slice(hashStart) : ''
        }`,
      )

    // // Force a reload to cause initial props to be called
    window.location.assign(routeAndParams)
  } else {
    addFlashMessageToStorage(init)
    routing.router.push(routing.route)
  }
}

const removeFlashMessage = (flashMessageId: string): void => {
  window.localStorage.removeItem(flashMessageId)
}

export const PageMessage = (props: PageMessageProps) => {
  const [message, setMessage] = React.useState<PageMessage>()
  const [isFirstRender, setIsFirstRender] = React.useState(true)
  const elRef = props.focusRef // check whether an external focus ref should be used for anchoring the message
    ? props.focusRef
    : React.createRef<HTMLDivElement>()

  const timedOut = (timeOut: number): boolean => timeOut < Date.now()

  React.useEffect(() => {
    if (
      message?.text !== '' &&
      typeof message?.text !== 'undefined' &&
      elRef !== null &&
      elRef.current !== null &&
      props.disableScroll !== null &&
      !props.disableScroll
    ) {
      // magic numbers: font size - line height
      window.scrollTo(
        0,
        elRef.current.offsetTop - elRef.current.clientHeight - 16 - 24,
      )
    }
  }, [message])

  React.useEffect(() => {
    isFirstRender
      ? setIsFirstRender(false)
      : setMessage({
          text: props.message,
          type: props.type,
        })
  }, [props.message])

  React.useEffect(() => {
    const itemInStorage =
      !clientIsIE() && window.localStorage.getItem(props.flashMessageId)
    if (itemInStorage) {
      const parsedItem = {
        ...JSON.parse(itemInStorage),
      }
      !timedOut(parsedItem.timeout) &&
        setMessage({
          text: parsedItem.text,
          type: parsedItem.type,
        })
      removeFlashMessage(props.flashMessageId)
    } else if (props.message && props.type) {
      setMessage({
        text: props.message,
        type: props.type,
      })
    } else if (
      props.urlParams?.messageText &&
      props.urlParams.messageType &&
      props.urlParams.messageId === props.flashMessageId
    ) {
      setMessage({
        text: props.urlParams.messageText,
        type: props.urlParams.messageType,
      })
    }
  }, [])

  // Only render known message types
  return message?.text &&
    message?.type &&
    ['error', 'info', 'success'].includes(message?.type || '') ? (
    props.focusRef ? ( // This allows an external focus ref to be used
      <div
        data-testid={`${message.type}-page-message`}
        className={props.className}
      >
        <PlatformMessage
          type={message.type}
          text={message.text}
          renderAnnouncer={props.renderAnnouncer}
        />
      </div>
    ) : (
      <div
        data-testid={`${message.type}-page-message`}
        className={props.className}
        ref={elRef}
      >
        <PlatformMessage
          type={message.type}
          text={message.text}
          renderAnnouncer={props.renderAnnouncer}
        />
      </div>
    )
  ) : (
    <React.Fragment></React.Fragment>
  )
}
